import { HTTP_METHODS } from "../common/constants";
import { makeNetworkCall } from "./network";
const BaseAuthURL = process.env.REACT_APP_API_BASE_URL_AUTH;

export const getIndustries = () => {
  const url = `${BaseAuthURL}/industry`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const getLOBs = () => {
  const url = `${BaseAuthURL}/line-of-business`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const handleSignupAPI = (body: any) => {
  const url = `${BaseAuthURL}/user`;
  return makeNetworkCall(HTTP_METHODS.POST, url, body);
};

export const getConsentData = () => {
  const url = `${BaseAuthURL}/consent-content`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const allowConsent = (body: any) => {
  const url = `${BaseAuthURL}/user`;
  return makeNetworkCall(HTTP_METHODS.PATCH, url, body);
};

export const handleLogin = (body: any) => {
  const url = `${BaseAuthURL}/magic-link`;
  return makeNetworkCall(HTTP_METHODS.POST, url, body);
};

export const retrieveToken = (tmpToken: string) => {
  const url = `${BaseAuthURL}/user/token`;
  return makeNetworkCall(HTTP_METHODS.POST, url, { token: tmpToken });
};

export const logoutHandler = () => {
  const url = `${BaseAuthURL}/user/token`;
  return makeNetworkCall(HTTP_METHODS.DELETE, url);
};