import { lazy, Suspense } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./components/atoms/loader";
import RequiredAuth from "./components/atoms/requiredAuth";
import Authentication from "./pages/authentication";
import CalendlyEventList from "./pages/socialCallback/calendlyEventList";

const LoginPage = lazy(() => import("./pages/login"));
const SignupPage = lazy(() => import("./pages/signup"));
const SocialCallback = lazy(() => import("./pages/socialCallback"));
const ConsentPage = lazy(() => import("./pages/consent"));
const DashboardPage = lazy(() => import("./pages/dashboard"));
const ProfilePage = lazy(() => import("./pages/profile"));
const PaymentsPage = lazy(() => import("./pages/payments"));
const PlayBookPage = lazy(() => import("./pages/playbook"));
const HelpCenterPage = lazy(() => import("./pages/helpCenter"));
const DigiDoublePage = lazy(() => import("./pages/digiDouble"));
const SettingsPage = lazy(() => import("./pages/settingsPage"));
const ScheduledCallsPage = lazy(() => import("./pages/fullScheduledCalls"));
const TransactionsPage = lazy(() => import("./pages/transactions"));
const SessionExpiredPage = lazy(() => import("./pages/sessionExpired"));

const App = () => {
  // import Interceptor and run once
  let navigate = useNavigate();
  // const [interceptorStarted, setInterceptorStarted] = useState(false);
  // if (!interceptorStarted) {
  //   SetupInterceptors(navigate);
  //   setInterceptorStarted(true);
  // }
  // ends

  return (
    <div className="App">
      <ToastContainer></ToastContainer>
      <Routes>
        <Route
          path="*"
          element={
            <Suspense fallback={<Loader />}>
              <Navigate to="/login" replace />
            </Suspense>
          }
        />
        <Route
          path="/sessionExpired"
          element={
            <Suspense fallback={<Loader />}>
              <SessionExpiredPage />
            </Suspense>
          }
        />

        <Route
          path="/authentication"
          element={
            <Suspense fallback={<Loader />}>
              <Authentication />
            </Suspense>
          }
        />
        <Route
          path="/login"
          element={
            <Suspense fallback={<Loader />}>
              <LoginPage />
            </Suspense>
          }
        />
        <Route
          path="/socialCB"
          element={
            <Suspense fallback={<Loader />}>
              <SocialCallback />
            </Suspense>
          }
        />
        <Route
          path="/calendlyEvents"
          element={
            <Suspense fallback={<Loader />}>
              <CalendlyEventList />
            </Suspense>
          }
        />
        <Route
          path="/signup"
          element={
            <Suspense fallback={<Loader />}>
              <SignupPage />
            </Suspense>
          }
        />
        <Route
          path="/consent"
          element={
            <RequiredAuth>
              <Suspense fallback={<Loader />}>
                <ConsentPage />
              </Suspense>
            </RequiredAuth>
          }
        />
        <Route
          path="/dashboard"
          element={
            <RequiredAuth>
              <Suspense fallback={<Loader />}>
                <DashboardPage />
              </Suspense>
            </RequiredAuth>
          }
        />
        <Route
          path="/playbook"
          element={
            <RequiredAuth>
              <Suspense fallback={<Loader />}>
                <PlayBookPage />
              </Suspense>
            </RequiredAuth>
          }
        />
        <Route
          path="/profile"
          element={
            <RequiredAuth>
              <Suspense fallback={<Loader />}>
                <ProfilePage />
              </Suspense>
            </RequiredAuth>
          }
        />
        <Route
          path="/payments"
          element={
            <RequiredAuth>
              <Suspense fallback={<Loader />}>
                <PaymentsPage />
              </Suspense>
            </RequiredAuth>
          }
        />
        <Route
          path="/settings"
          element={
            <RequiredAuth>
              <Suspense fallback={<Loader />}>
                <SettingsPage />
              </Suspense>
            </RequiredAuth>
          }
        />
        <Route
          path="/helpCenter"
          element={
            <RequiredAuth>
              <Suspense fallback={<Loader />}>
                <HelpCenterPage />
              </Suspense>
            </RequiredAuth>
          }
        />
        <Route
          path="/digiDouble"
          element={
            <RequiredAuth>
              <Suspense fallback={<Loader />}>
                <DigiDoublePage />
              </Suspense>
            </RequiredAuth>
          }
        />
        <Route
          path="/scheduledCalls"
          element={
            <RequiredAuth>
              <Suspense fallback={<Loader />}>
                <ScheduledCallsPage />
              </Suspense>
            </RequiredAuth>
          }
        />
        <Route
          path="/transactions"
          element={
            <RequiredAuth>
              <Suspense fallback={<Loader />}>
                <TransactionsPage />
              </Suspense>
            </RequiredAuth>
          }
        />
      </Routes>
    </div>
  );
};

export default App;
