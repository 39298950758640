import axios, { AxiosRequestConfig, AxiosResponse, Method } from "axios";
import { HTTP_METHODS, LOCAL_STORAGE_VARIABLES } from "../common/constants";
const BaseAuthURL = `${process.env.REACT_APP_API_BASE_URL}auth`;

export const makeNetworkCall = async (
  method: Method,
  url: string,
  params: { [key: string]: any } = {}
): Promise<AxiosResponse> => {
  const accessToken = localStorage.getItem(LOCAL_STORAGE_VARIABLES.ACCESS_TOKEN);
  const refreshToken = localStorage.getItem(LOCAL_STORAGE_VARIABLES.REFRESH_TOKEN);

  // axios Config modification
  let payload = { ...params };
  let headers: { [key: string]: string } = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };

  if (method === HTTP_METHODS.PUT && params?.type === "file") {
    headers = {};
    payload = params.file;
  }

  // axios final Config
  const axiosConfig: AxiosRequestConfig = {
    method,
    url,
    headers: headers,
    data: payload,
    params: method === HTTP_METHODS.GET ? payload : {}
  };
  try {
    const response = await axios(axiosConfig);
    return response;
  } catch (error: any) {
    const originalRequest = error.config;
    // Check if the error is due to an expired access token
    if (error?.response?.status === 401 && error?.response?.data?.message === "Invalid Access Token") {
      // Refresh the access token using the refresh token
      try {
        const refreshConfig: AxiosRequestConfig = {
          method: "post",
          url: `${BaseAuthURL}/access-token`,
          headers: {
            Authorization: `Bearer ${refreshToken}`,
            "Content-Type": "application/json",
          },
          data: {},
        };
        const refreshResponse = await axios(refreshConfig);
        const newAccessToken = refreshResponse?.data?.data?.accessToken || "";
        // Update the access token in the local storage
        localStorage.setItem(LOCAL_STORAGE_VARIABLES.ACCESS_TOKEN, newAccessToken);
        // Retry the original request with the new access token
        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
        try {
          const retryResponse = await axios(originalRequest);
          return retryResponse;
        } catch (retryResponseError: any) {
          return retryResponseError.response;
          // throw error;
        }
      } catch (refreshError: any) {
        // Handle refresh token failure (e.g., redirect to login)
        console.error("SESSION EXPIRED", refreshError);
        localStorage.clear();
        window.location.href = "/sessionExpired";
        return refreshError.response;
        // throw refreshError;
      }
    } else {
      // Handle other errors (e.g., network issues)
      console.error("Error making network call:", error);
      return error.response;
      // throw error;
    }
  }
};
