import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/atoms/loader";
import { retrieveToken } from "../../services/authForms";
import Button from "../../components/atoms/button";

const Authentication = () => {
  let url = new URL(window?.location as any);
  let tmpToken: string = url.searchParams.get("token") as string;
  // logConsole("tmpToken", tmpToken);

  const navigate = useNavigate();
  const [authError, setAuthError] = useState("");

  const handleCB = async (tmpToken: string) => {
    const tokenResponse = await retrieveToken(tmpToken);
    // logConsole("tokenResponse", tokenResponse);
    if (tokenResponse?.status === 200) {
      window.localStorage.setItem(
        "accessToken",
        tokenResponse?.data?.data?.accessToken
      );
      window.localStorage.setItem(
        "refreshToken",
        tokenResponse?.data?.data?.refreshToken
      );

      navigate("/dashboard");
    } else {
      setAuthError(tokenResponse?.data?.exception?.error?.message);
    }
  };

  useEffect(() => {
    if (!!tmpToken) {
      handleCB(tmpToken);
    }
  }, []);

  return (
    <>
      {authError === "" && <Loader />}
      {authError !== "" && (
        <div style={{ margin: "15vh auto", textAlign: "center" }}>
          <img src="./assets/images/brokenLink.png" alt="" />

          <h1 style={{ margin: "3rem 0" }}>{authError} . . .</h1>

          <Button type="link" href="/login">
            Back to login
          </Button>
        </div>
      )}
    </>
  );
};

export default React.memo(Authentication);
