import { DOMParser } from "@xmldom/xmldom";
import PizZip from "pizzip";
import { toast } from "react-toastify";

export const logConsole = (value1: any, value2: any): void => {
  console.log(value1);
  console.log(value2);
  console.log("=================\n");
};

export const getFileType = (file: any = null): string => {
  if (file !== null) {
    const { type = "" } = file;
    let splitted: [string] = type.split("/");
    let fileType: string = splitted[splitted.length - 1];
    return fileType;
  }
  return "";
};

export const getFileExt = (file: any): string => {
  if (file !== null) {
    const { name } = file;
    let splitted: [string] = name.split(".");
    let fileExt = splitted[splitted.length - 1];
    return fileExt;
  }
  return "";
};

const str2xml = (str: any) => {
  if (str.charCodeAt(0) === 65279) str = str.substr(1);
  return new DOMParser().parseFromString(str, "text/xml");
};

const getDocxText = (content: any) => {
  const zip = new PizZip(content);
  const xml = str2xml(zip.files["word/document.xml"].asText());
  const paragraphsXml = xml.getElementsByTagName("w:p");
  const paragraphs = [];

  for (let i = 0, len = paragraphsXml.length; i < len; i++) {
    let fullText = "";
    const textsXml = paragraphsXml[i].getElementsByTagName("w:t");
    for (let j = 0, len2 = textsXml.length; j < len2; j++) {
      const textXml = textsXml[j];
      if (textXml.childNodes) {
        fullText += textXml.childNodes[0].nodeValue;
      }
    }
    if (fullText) {
      paragraphs.push(fullText);
    }
  }
  return paragraphs;
};

const extractDocext = (file: any): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const content = e.target.result;
      const paragraphs = getDocxText(content);
      let fileData = paragraphs.join(" ");
      resolve(fileData);
    };
    reader.onerror = (e: any) => {
      logConsole("file error: ", e);
      reject("file error");
    };

    reader.readAsBinaryString(file);
  });
};

const extractPDFText = (pdfUrl: any) => {
  const PDFJS = window.pdfjsLib;
  let pdf = PDFJS.getDocument(pdfUrl);
  return pdf.promise.then(function (pdf: any) {
    let totalPageCount = pdf.numPages;
    let countPromises = [];
    for (let currentPage = 1; currentPage <= totalPageCount; currentPage++) {
      let page = pdf.getPage(currentPage);
      countPromises.push(
        page.then(function (page: any) {
          let textContent = page.getTextContent();
          return textContent.then(function (text: any) {
            return text.items
              .map(function (s: any) {
                return s.str;
              })
              .join("");
          });
        })
      );
    }

    return Promise.all(countPromises).then(function (texts) {
      return texts.join("");
    });
  });
};

export const getFileContent = async (file: any, fileType: string) => {
  let fileData = "";
  if (
    fileType === "vnd.openxmlformats-officedocument.wordprocessingml.document"
  ) {
    fileData = await extractDocext(file);
  }
  if (fileType === "pdf") {
    const url = URL.createObjectURL(file);
    fileData = await extractPDFText(url);
  }
  return fileData;
};

export const storeInLocal = (key: string, value: any) => {
  localStorage.setItem(key, value);
};

export const getLocalData = (key: string) => {
  return JSON.parse(localStorage?.getItem(key) || "{}");
};

// toast

export const toastSuccess = (msg: string) => {
  toast.success(msg, {
    position: "top-center",
    autoClose: 3000,
    theme: "colored",
  });
};

export const toastError = (msg: string) => {
  toast.error(msg, {
    position: "top-center",
    autoClose: 3000,
    theme: "colored",
  });
};

export const getDay = (date: any) => {
  if(!!date) {
  const day = new Date(date).getDate();
  return "" + day;
  }
  else {
    return '';
  }
};

export const getMonth = (date: any) => {
  const month = new Date(date).getMonth() + 1;
  return "" + month;
  

};

export const getMonthName = (date: any, short: boolean) => {
  const monthShortNames = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
  ];
  const monthFullNames = [
    "january",
    "feburary",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "sepetember",
    "october",
    "november",
    "december",
  ];
  const month = new Date(date).getMonth();
  if (short) {
    const monthName = monthShortNames[month];
    return monthName;
  }
  const monthName = monthFullNames[month];
  return monthName;
};

export const getYear = (date: any) => {
  if(!!date) {
    const year = new Date(date).getFullYear();
    return "" + year;
  }
  else {
    return '';
  }

};


export const capitalize = (str: string) => {
  return str && str[0].toUpperCase() + str.slice(1);
}


export const isUrlValid = (str: string, type: string) => {

  switch (type) {
    case 'Linkedin': case 'linkedin':
      const linkedIn = new RegExp(
        //eslint-disable-next-line
        '^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/?'
      )
      return linkedIn.test(str);
    case 'Youtube': case 'youtube':
      const youtube = new RegExp(
        //eslint-disable-next-line
        '^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+'
      )
      return youtube.test(str);
    case 'Twitter': case 'twitter':
      const twitter = new RegExp(
        //eslint-disable-next-line
        'http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)'
      );
      return twitter.test(str);
    case 'Medium': case 'medium':
      const medium = new RegExp(
        //eslint-disable-next-line
        '^(?:https?:)?\/\/medium\.com\/@([-a-zA-Z0-9]+)\/?'
      )
      return medium.test(str);
    case 'Substack': case 'substack':
      const substack = new RegExp(
        //eslint-disable-next-line
        '^(http(s)?:\/\/)?([\w]+\.)?substack\.com\/([-a-zA-Z0-9_@./]+)\/?'
      );
      return substack.test(str);
    case 'Social': case 'social': case 'default':
      const pattern = new RegExp(
        //eslint-disable-next-line
        '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
        '((\\d{1,3}\\.){3}\\d{1,3}))' +
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
        '(\\?[;&a-z\\d%_.~+=-]*)?' +
        '(\\#[-a-z\\d_]*)?$',
        'i'
      );
      return pattern.test(str);
  }
}

export const yearValidation = (year: any, ev: any) => {
  var text = /^[0-9]+$/;
  // if(ev.type=="blur" || year.length==4 && ev.keyCode!=8 && ev.keyCode!=46) {
  if (year !== 0) {
    if ((year !== "") && (!text.test(year))) {
      return { status: false, message: "Please Enter Numeric Values Only" }
    }
    if (year.length !== 4) {
      return { status: false, message: "Year is not proper. Please check" }
    }
    var current_year = new Date().getFullYear();
    if ((year < 1920) || (year > current_year)) {
      return { status: false, message: "Year should be in range 1920 to current year" }
    }
    return { status: true, message: "" }
  }
  // }
}


export const dobMatch = (date: string) => {


  if(new Date(date) > new Date()){
    return {message:"Date of birth can not be greater than current date", status:false}
  }
  let operator: any = date.split('-');
  let datepart: any = [];
  if (operator.length > 1) {
    datepart = date.split('-');
  }
  let year = parseInt(datepart[0]);
  let month = parseInt(datepart[1]);
  let day = parseInt(datepart[2]);
  let ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  if (month === 1 || month > 2) {
    if (day > ListofDays[month - 1]) {
      return {message:"Please use proper date", status:false}    
    }
  } else if (month === 2) {
    let leapYear = false;
    if ((!(year % 4) && year % 100) || !(year % 400)) leapYear = true;
    if ((leapYear === false) && (day >= 29)) return {message:"Please change month or date", status:false} 
    else
      if ((leapYear === true) && (day > 29)) {
        return {message:"Please change month or date", status:false} 
      }
  }
  return {message:"", status:true} 

}

export const printDiv = (divName: any, headValue?: any) => {
  let printContents: any = document.getElementById(divName)?.innerHTML
  if (headValue) {
    printContents = `<h3 style="color:black">${headValue}</h3>` + printContents;
  }
  return { content: printContents, printStat: true }
}

export const filterValue = (array: Array<number | string | {} | any>, matchedVal: string | number | {}) => {
  let filterArray = array.filter((value: number | string | {} | any) => {
    if (typeof value === "object") {
      if (value?.name === matchedVal) { return value }
    } else {
      if (value === matchedVal) { return matchedVal }
    }
  })
  return filterArray
}



