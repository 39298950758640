import { HTTP_METHODS, SOCIAL_APPS, URLS } from "../common/constants";
import { logConsole } from "../common/util";
import { makeNetworkCall } from "./network";

export const handleSocialSSOAPI = (type: string) => {
  let ssoUrl = "";
  switch (type) {
    case SOCIAL_APPS.LINKEDIN:
      ssoUrl = `${URLS.SSO_LINKEDIN}`;
      break;
    case SOCIAL_APPS.YOUTUBE:
      ssoUrl = process.env.REACT_APP_YOUTUBE_SSO_URL as string;
      break;
  }
  if (!!ssoUrl) window.open(ssoUrl, "_blank");
};

export const handleSocialCBAPI = (
  userId: string,
  code: string,
  type: string
) => {
  let url = `${URLS.AUTH}/social-account/info`;
  let qParams = {
    userId: userId,
    accountType: type,
    accountAuthCode: code,
  };
  logConsole("handleSocialCBAPI url", url);
  logConsole("handleSocialCBAPI qParams", qParams);
  return makeNetworkCall(HTTP_METHODS.GET, url, qParams);
};

export const getCalendlyEvents = (code: string) => {
  let url = `${URLS.USER}/calendly/event`;
  let qParams = {
    accountAuthCode: code,
  };
  return makeNetworkCall(HTTP_METHODS.GET, url, qParams);
};

export const updateCalendly = (data: any) => {
  let url = `${URLS.USER}/profile/calendly`;
  return makeNetworkCall(HTTP_METHODS.PATCH, url, data);
};

export const getYoutubeData = (accessToken: string) => {
  let url = `${URLS.AUTH}/social-account/info`;
  let qParams = {
    accountType: "youtube",
    accountAuthCode: accessToken,
  };
  return makeNetworkCall(HTTP_METHODS.GET, url, qParams);
};
